<template>
      <div class="container-fluid">
        <div class="row">

            <sidebar></sidebar>
            <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10 h-100 pb-5">   
                <div class="row">
                    <div class="col-12 mb-5">
                        <app-header></app-header>
                        <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                    </div>
                </div>  
               
              
               <!-- Clubs -->
                <section class="club-section px-0 mx-0 px-lg-5 mx-lg-5  mt-0 mt-md-5 pb-5 ">
                    <div class="container-fluid ">
                        <div class="row justify-content-start mb-5 mt-md-5 mt-lg-0 pt-5 pt-sm-0">

                            <div class="col-12 px-0 px-sm-3">
                                <div class="d-flex">                
                                    <!-- Stars -->
                                    <span class="badge badge-primary d-flex align-items-center">
                                        <img src="/static/images/icons/evaluations/star.png" alt="" class="img-fluid mr-3 star-ic">
                                        Star: 2
                                    </span>
                                </div>
                            </div>

                            <div class="col-12 mt-4 pt-1  px-0 px-sm-3">
                                <!-- Club Description -->
                                <div class="club-bg club-table " >
                                    <table class="table-blue-wide table-blue-wide-p w-100">
                                        <thead>
                                            <tr>
                                                <th>კლუბის სახელი</th>
                                                <th>ლოგო</th>
                                                <th>საკონტაქტო პირი</th>
                                                <th>მობილური</th>
                                                <th>ელ.ფოსტა</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-label="კლუბის სახელი">Oriental club</td>
                                                <td data-label="ლოგო"><img src="/static/images/icons/evaluations/recover.png" alt="" class="img-fluid mr-3" style="height: 25px;"></td>
                                                <td data-label="საკონტაქტო პირი">იური ნიკოლეიშვილი</td>
                                                <td data-label="მობილური">551070391</td>
                                                <td data-label="ელ.ფოსტა">orientalclub.ug@gmail.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="col-12 mt-4 pt-1  px-0 px-sm-3">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <div class="d-inline-block bg-white-88 border-round py-md-0 py-lg-2 px-3 px-sm-4">
                                            <div class="d-flex align-items-center justify-content-between py-3 py-sm-2 px-0 px-sm-3 mx-1">
                                                <div class="d-flex align-items-center mr-4 pr-2">
                                                    <img src="/static/images/icons/evaluations/recover.png" alt="accepted evaluation" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                                    <span class="s-b-1">პროცესშია</span>
                                                </div>
                                                <div class="d-flex align-items-center mr-4 pr-2">
                                                    <img src="/static/images/icons/evaluations/fail.png" alt="failed evaluation" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                                    <span class="s-b-1">უარყოფილია</span>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <img src="/static/images/icons/evaluations/accept.png" alt="recover evaluation" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                                    <span class="s-b-1">დამტკიცებულია</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-8 text-md-center offset-md-2 mt-4  col-lg-6 offset-lg-0 text-lg-left">
                                        <span class="link-red f-heading-bold">
                                            პროექტის დამატება შესაძლებელია თვის
                                            ნებისმიერ რიცხვში გარდა
                                            1-დან 5-მდე და 15-დან 20-მდე
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-md-2 mt-lg-5 pt-4  px-0 px-sm-3">
                                <div class="club-bg">
                                    <!-- Projects -->
                                    <table class="table-blue-wide table-blue-wide-p"> 
                                        <thead>
                                            <tr>
                                                <th>პროექტი</th>
                                                <th>პროექტის ღირებულება</th>
                                                <th>დამტკიცებული ღირებულება</th>
                                                <th>ფინანსები</th>
                                                <th class="text-center">აღწერა</th>
                                                <th>კომენტარი</th>
                                                <th>წაშლა</th>
                                                <th class="text-center">სტატუსი</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-label="პროექტი">შეხვედრა დათო ტურაშვლითან</td>
                                                <td data-label="პროექტის ღირებულება">5 ლარი</td>
                                                <td data-label="დამტკიცებული ღირებულება">5 ლარი</td>
                                                <td data-label="ფინანსები" class="text-center"><img src="/static/images/icons/fee-pay-grey.png"  class="img-fluid ic-w"></td>
                                                <td data-label="აღწერა" class="text-center" onclick="callModalClubInfo()"><img src="/static/images/icons/information-grey.png"  class="img-fluid ic"></td>
                                                <td data-label="კომენტარი">ბიუჯეტის ველში.</td>
                                                <td data-label="წაშლა">წაშლა შეუძლებელია</td>
                                                <td data-label="სტატუსი" class="text-center"> <img src="/static/images/icons/evaluations/accept.png"  class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic"></td>
                                            </tr>
                                            <tr>
                                                <td data-label="პროექტი">შეხვედრა დათო ტურაშვლითან</td>
                                                <td data-label="პროექტის ღირებულება">5 ლარი</td>
                                                <td data-label="დამტკიცებული ღირებულება">5 ლარი</td>
                                                <td data-label="ფინანსები" class="text-center"><img src="/static/images/icons/fee-pay-grey.png"  class="img-fluid ic-w"></td>
                                                <td data-label="აღწერა" class="text-center"><img src="/static/images/icons/information-grey.png"  class="img-fluid ic"></td>
                                                <td data-label="კომენტარი">გთხოვთ, შეცვალოთ აუდიტორია, რადგან კლუბების სივრცე როგორც იცით #116-ია. გთხოვთ ყველა საჭირო რესურსის ჩამონათვალი თანმიმდევრულად ცალ-ცალკე გაწეროთ რაოდენობებისა და ფასების მითითებით. გაითვალისწინეთ, რომ უნივერსიტეტისგან მოთხოვნილი არსებული რესურსი მაგალითად: ბრენდირებული ღვინო, პუფი, მიკროფონი დ აპარატურა უფასოა ამიტომ მას ნუ მოაქცევთ გაწერილი ბიუჯეტის ველში.</td>
                                                <td data-label="წაშლა">წაშლა შეუძლებელია</td>
                                                <td data-label="სტატუსი" class="text-center"> <img src="/static/images/icons/evaluations/accept.png"  class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic"></td>
                                            </tr>
                                        </tbody>
                                    </table>        
                                </div>
                            </div> 

                        </div>
                    </div>
                </section>
                

                <!-- Vertically centered modal -->
                <!-- subject_groups -->
                <div class="modal fade "  tabindex="-1"  aria-hidden="true" role="dialog" id="club_info">
                    <div class="modal-dialog modal-dialog-centered modal-md">
                        <!-- Modal content-->
                        <div class="modal-content modal-blue">
                            <div class="modal-body">
                                <div class="row ">
                                    <div class="col-12 d-flex">
                                        <h3>UG ლიტერატურული კლუბი</h3>
                                        <button class="btn ml-auto btn-light-bold">ბეჭდვა</button>
                                    </div>
                                    <!-- project Name -->
                                    <div class="col-12 mt-3">
                                        <hr>
                                        <h4>პროექტის სათაური</h4>
                                        <hr>
                                    </div>
                                    <div class="col-12">

                                        <div class="form-group row">
                                            <label for="damoukidebeli" class="col-sm-3 col-form-label sr-only">project name</label>
                                            <div class="col-sm-8 offset-sm-2">
                                                <input type="text" class="form-control" id="damoukidebeli" name="damoukidebeli" value="შეხვედრა დათო ტურაშვლითან">
                                            </div>
                                        </div>

                                        <!-- m_tanxa -->
                                        <div class="form-group row align-items-center">
                                            <label for="m_tanxa" class="col-4 col-form-label text-left">სტუდენტური ფონდიდან მოთხოვნილი თანხა</label>
                                            <div class="col-6 ">
                                                <input type="text" class="form-control" id="m_tanxa" value="5" name="m_tanxa">
                                            </div>
                                        </div>

                                        <!-- studcount -->
                                        <div class="form-group row align-items-center">
                                            <label for="studcount" class="col-4 col-form-label text-left">სავარაუდო მონაწილეთა რაოდენობა:</label>
                                            <div class="col-6 ">
                                                <input type="text" class="form-control" id="studcount" value="5" name="studcount">
                                            </div>
                                        </div>

                                        <!-- startdate -->
                                        <div class="form-group row align-items-center">
                                            <label for="startdate" class="col-4 col-form-label text-left">პროექტის დაწყების თარიღი:</label>
                                            <div class="col-6 ">
                                                <input type="date" class="form-control" id="startdate"  name="startdate">
                                            </div>
                                        </div>

                                        <!-- startdate -->
                                        <div class="form-group row align-items-center">
                                            <label for="enddate" class="col-4 col-form-label text-left">პროექტის დასრულების თარიღი:</label>
                                            <div class="col-6 ">
                                                <input type="date" class="form-control" id="enddate"  name="enddate">
                                            </div>
                                        </div>

                                        <!-- adgili -->
                                        <div class="form-group row align-items-center">
                                            <label for="adgili" class="col-4 col-form-label text-left">ადგილი</label>
                                            <div class="col-6 ">
                                                <input type="text" class="form-control" id="adgili"  name="adgili">
                                            </div>
                                        </div>


                                        <!-- drostart -->
                                        <div class="form-group row align-items-center">
                                            <label for="drostart" class="col-4 col-form-label text-left">პროექტის დაწყების დრო</label>
                                            <div class="col-6 ">
                                                <input type="text" class="form-control" id="drostart"  name="drostart">
                                            </div>
                                        </div>

                                        <!-- drostart -->
                                        <div class="form-group row align-items-center">
                                            <label for="droend" class="col-4 col-form-label text-left">პროექტის დასრულების დრო</label>
                                            <div class="col-6 ">
                                                <input type="text" class="form-control" id="droend"  name="droend">
                                            </div>
                                        </div>
                                        <div class="text-left">
                                            <span>
                                                მე, ზემოხსენებული პროექტის ხელმძღვანელი 
                                                <strong>ერეკლე გოგიშვილი                 </strong>ვიღებ 
                                                პასუხისმგებლობას, რომ სტუდენტური ფონდიდან მიღებულ 
                                                ყოველგვარ ფინანსურ ან მატერიალურ დახმარებას გამოვიყენებ 
                                                ისე, როგორც გათვალისწინებულია პროექტით. მე თანახმა ვარ
                                                დავიცვა ფონდის მიერ დადგენილი ანგარიშგების წესები. პროექტის 
                                                განხორციელებაში დაშვებული დარღვევების შემთხვევაში, თანახმა 
                                                ვარ გრანტის სახით მიღებული თანხა და ამ თანხით შეძენილი ქონება 
                                                დავუბრუნო ფონდს, მისი გადაწყვეტილების შესაბამისად.  
                                            </span>
                                        </div>

                                    </div>
                                    <!-- project description -->
                                    <div class="col-12 mt-3">
                                        <hr>
                                            <h4>პროექტის აღწერა</h4>
                                        <hr>
                                    </div>
                                    <div class="col-12">
                                        <!-- agwera -->
                                        <div class="form-group row pt-4">
                                            <div class="col-12 ">
                                                <h4 class="text-left mb-4">პრობლემის განსაზღვრა,
                                                    მიზნები: (რამ გამოიწვია ამ პროექტის განხორციელების იდეა, 
                                                    აქტუალობა, მიზნები, რას ემსახურება თქვებნი პროექტი)</h4>
                                                <textarea name="agwera" class="form-control" rows="8" id="agwera">
                                                    საქართველოს უნივერსიტეტის ლიტერატურული კლუბის მიზანია ლიტერატურისა და წიგნის პოპულარიზაცია, სტუდენტების ინიციატივების პროექტის სახით განხორციელება. ინტელექტუალური, კრეატიული და შემეცნებითი ხასიათის ღონისძიებების ორგანიზება. ამასთან, სტუდენტების ჩართულობა არაფორმალურ განათლებაში და საუნივერსიტეტო ცხოვრების გამრავალფეროვნება.
                                                </textarea>
                                            </div>
                                            <label for="agwera" class="col-sm-3 col-form-label sr-only">agwera</label>
                                        </div>
                                        <!-- rezume -->
                                        <div class="form-group row pt-4">
                                            <div class="col-12 ">
                                                <h4 class="text-left mb-4">
                                                    პროექტის მოკლე რეზიუმე: (რამოდენიმე წინადადებით პროექტის შესახებ, რა, სად, როგორ , ვინ, როდის)</h4>
                                                <textarea name="rezume" class="form-control" rows="8" id="agwera">
                                                    საქართველოს უნივერსიტეტის ლიტერატურული კლუბის მიზანია ლიტერატურისა და წიგნის პოპულარიზაცია, სტუდენტების ინიციატივების პროექტის სახით განხორციელება. ინტელექტუალური, კრეატიული და შემეცნებითი ხასიათის ღონისძიებების ორგანიზება. ამასთან, სტუდენტების ჩართულობა არაფორმალურ განათლებაში და საუნივერსიტეტო ცხოვრების გამრავალფეროვნება.
                                                </textarea>
                                            </div>
                                            <label for="rezume" class="col-sm-3 col-form-label sr-only">rezume</label>
                                        </div>

                                        <!-- gegma -->
                                        <div class="form-group row pt-4">
                                            <div class="col-12 ">
                                                <h4 class="text-left mb-4">
                                                    სამოქმედო გეგმა: (საკითხების ჩამონათვალი რომელიც უნდა გააკეთოთ, რა საკითხს როდის და როგორ აკეთებთ)
                                                </h4>
                                                <textarea name="gegma" class="form-control" rows="8" id="gegma">
                                                    საქართველოს უნივერსიტეტის ლიტერატურული კლუბის მიზანია ლიტერატურისა და წიგნის პოპულარიზაცია, სტუდენტების ინიციატივების პროექტის სახით განხორციელება. ინტელექტუალური, კრეატიული და შემეცნებითი ხასიათის ღონისძიებების ორგანიზება. ამასთან, სტუდენტების ჩართულობა არაფორმალურ განათლებაში და საუნივერსიტეტო ცხოვრების გამრავალფეროვნება.
                                                </textarea>
                                            </div>
                                            <label for="gegma" class="col-sm-3 col-form-label sr-only">gegma</label>
                                        </div>

                                        <!-- shedegebi -->
                                        <div class="form-group row pt-4">
                                            <div class="col-12 ">
                                                <h4 class="text-left mb-4">
                                                    მოსალოდნელი შედეგები: (პროექტის განხორციელების შემდეგ რა შედეგებს მიიღებთ, რა შეიცვლება უკეთესობისკენ)
                                                </h4>
                                                <textarea name="shedegebi" class="form-control" rows="8" id="shedegebi">
                                                    საქართველოს უნივერსიტეტის ლიტერატურული კლუბის მიზანია ლიტერატურისა და წიგნის პოპულარიზაცია, სტუდენტების ინიციატივების პროექტის სახით განხორციელება. ინტელექტუალური, კრეატიული და შემეცნებითი ხასიათის ღონისძიებების ორგანიზება. ამასთან, სტუდენტების ჩართულობა არაფორმალურ განათლებაში და საუნივერსიტეტო ცხოვრების გამრავალფეროვნება.
                                                </textarea>
                                            </div>
                                            <label for="shedegebi" class="col-sm-3 col-form-label sr-only">shedegebi</label>
                                        </div>

                                        <!-- sainiciativo -->
                                        <div class="form-group row pt-4">
                                            <div class="col-12 ">
                                                <h4 class="text-left mb-4">
                                                    საინიციატივო ჯგუფი: (სია იმ სტუდენტების/ადამიანების ვინც არის ჩართული პროექტის განხორციელებაში და მოკლედ მათი გამოცდილება)                                </h4>
                                                <textarea name="sainiciativo" class="form-control" rows="8" id="sainiciativo">
                                                    საქართველოს უნივერსიტეტის ლიტერატურული კლუბის მიზანია ლიტერატურისა და წიგნის პოპულარიზაცია, სტუდენტების ინიციატივების პროექტის სახით განხორციელება. ინტელექტუალური, კრეატიული და შემეცნებითი ხასიათის ღონისძიებების ორგანიზება. ამასთან, სტუდენტების ჩართულობა არაფორმალურ განათლებაში და საუნივერსიტეტო ცხოვრების გამრავალფეროვნება.
                                                </textarea>
                                            </div>
                                            <label for="sainiciativo" class="col-sm-3 col-form-label sr-only">sainiciativo</label>
                                        </div>

                                    </div>
                                </div>
                                <div class="row"> 
                                    <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
                                        <button type="button" class="btn btn-light-bold" data-dismiss="modal">ok</button>
                                    </div>
                                </div>

                            </div>
                    </div>
                    </div>
                </div>



            </main>
        </div>
    </div>
</template>
<script>

import sidebar from '../../components/navbar/sidebar';
import appHeader from '../../components/app-header';
import pageHeader from '../../components/page/page-header';


export default {
    name:'clubs-manage',
    components: { sidebar, appHeader, pageHeader },
    computed: {
        pageData() {
        return {
            title: this.$t('clubs-manage.title'),
            breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
            },
            {
            icon: '',
            title: this.$t('clubs-manage.title'),
            link: '/clubs/manage',
            isActive: true,
            }],
        }; 
        },
    },
  
}
</script>
